import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mainStore, userToolStore, helpdeskStore, } from '@/utils/store-accessor';
import ShowDialog from '@/components/ShowDialog.vue';
import Create from './Create.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headersUser = [
            { text: 'Solicitante', value: 'user_id' },
            { text: 'Título', value: 'title' },
            { text: 'Status', value: 'status' },
            { text: 'Categoria', value: 'subject' },
            { text: 'Nível', value: 'level' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Detalhes + Chat', value: 'actions' },
        ];
        this.headersSuporte = [
            { text: 'Solicitante', value: 'user_id' },
            { text: 'Escola', value: 'company_id' },
            { text: 'Título', value: 'title' },
            { text: 'Status', value: 'status' },
            { text: 'Categoria', value: 'subject' },
            { text: 'Nível', value: 'level' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Detalhes + Chat', value: 'actions' },
        ];
        this.selectPeriodDate = [];
        this.search = '';
        this.menu = false;
        this.searchStatus = 'Todos';
        this.searchCategory = 'Todos';
        this.items = [];
        this.loading = false;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`Helpdesk`]) {
            return userToolStore.userToolsIndexed[`Helpdesk`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`Helpdesk`]) {
            return userToolStore.userToolsIndexed[`Helpdesk`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`Helpdesk`]) {
            return userToolStore.userToolsIndexed[`Helpdesk`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`Helpdesk`]) {
            return userToolStore.userToolsIndexed[`Helpdesk`][0].allow_delete;
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get formattedDate() {
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        }
        else if (this.selectPeriodDate.length === 1) {
            return `${this.formatDate(this.selectPeriodDate[0])}`;
        }
        return '';
    }
    itemSubject(item) {
        switch (item) {
            case 'Dúvidas':
                return 'var(--v-blue150)';
            case 'Novo módulo':
            case 'Melhoria':
                return 'var(--v-blue200)';
            case 'Bug/Erro':
                return 'var(--v-blue300)';
            case 'Problema com pedido':
                return 'var(--v-blue800)';
            default:
                return '';
        }
    }
    get filteredItems() {
        let item = this.items;
        if (this.searchStatus !== 'Todos') {
            item = item.filter((f) => f.status_id === this.searchStatus);
        }
        if (this.searchCategory !== 'Todos') {
            item = item.filter((f) => f.subject_id === this.searchCategory);
        }
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            const startDate = new Date(start);
            const endDate = new Date(end);
            item = item.filter((f) => {
                const itemDate = new Date(f.created_at);
                return itemDate >= startDate && itemDate <= endDate;
            });
        }
        item.sort((a, b) => {
            const dataA = new Date(a.created_at).getTime();
            const dataB = new Date(b.created_at).getTime();
            return dataB - dataA;
        });
        return item;
    }
    async showItem(id) {
        const rota = this.$router.resolve({ name: 'main-helpdesk-edit', params: { id } });
        window.open(rota.href, '_blank');
    }
    async onClose() {
        const form = this.$refs.createTicket;
        form.close();
    }
    clearFilters() {
        this.selectPeriodDate = [];
        this.search = '';
        this.searchStatus = 'Todos';
        this.searchCategory = 'Todos';
    }
    async onConfirm() {
        const form = this.$refs.createTicket;
        form.close();
        await this.buscar();
    }
    async buscar() {
        this.loading = true;
        this.items = await helpdeskStore.getHelpdeskTickets();
        this.loading = false;
    }
    async showCreateTicket() {
        const form = this.$refs.createTicket;
        form.show();
    }
    async mounted() {
        await this.buscar();
    }
};
List = __decorate([
    Component({
        components: {
            ShowDialog,
            Create,
            ButtonComponent,
            PeriodSelectorComponent,
        },
    })
], List);
export default List;
